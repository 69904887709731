@import 'variables';
@import 'swiper/swiper-bundle.css';

@font-face {
  font-family: 'Rubik';
  src: url('/fonts/rubik.ttf');
}

:root {
  --dark-color: #001529;
  --light-color: #fff;
  --primary-color: #ae7aff;
  --grey-color: #5f646d;
  --dark-hover-color: #161616;
}

body {
  font-family: 'Rubik', sans-serif;
  font-size: 14px;
}

.custom-notification {
  color: #fff;
  border-radius: 0;
  background: #05aa3f;

  &.warning-notification {
    background: #ff9200;
  }

  &.info-notification {
    background: #1890ff;
  }

  &.error-notification {
    background: #080808;
  }

  &.success-notification {
    background: #05aa3f;
  }

  .anticon.ant-notification-notice-icon,
  .ant-notification-notice-message,
  .ant-notification-notice-description,
  .ant-notification-notice-close {
    color: #fff;
  }

  .ant-notification-notice-message,
  .ant-notification-notice-close {
    font-size: 18px;
  }

  .ant-notification-notice-description {
    font-size: 14px;
  }

  .ant-notification-notice-close {
    top: 10px;
  }
}
