// Custom Theming for NG-ZORRO
// For more information: https://ng.ant.design/docs/customize-theme/en
@import '../../node_modules/ng-zorro-antd/ng-zorro-antd.less';

// Override less variables to here
// View all variables: https://github.com/NG-ZORRO/ng-zorro-antd/blob/master/components/style/themes/default.less

// @primary-color: #1890ff;
@light-grey-color: #f5f5f5;
@blue-color: #2c7df6;
@input-height: 40px;
@mobile-width: 768px;

// ant-select------------------
.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  height: @input-height;
  border: none;
  background-color: @light-grey-color;
  border-radius: 8px;
  display: flex;
  align-items: center;
}

.ant-select-single.ant-select-show-arrow .ant-select-selection-search {
  display: flex;
  align-items: center;
}

.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input)
.ant-select-selector {
  box-shadow: none;
}

.ant-select-status-error.ant-select:not(.ant-select-disabled):not(.ant-select-customize-input):not(
    .ant-pagination-size-changer
  ).ant-select-open
.ant-select-selector,
.ant-select-status-error.ant-select:not(.ant-select-disabled):not(.ant-select-customize-input):not(
    .ant-pagination-size-changer
  ).ant-select-focused
.ant-select-selector {
  box-shadow: none;
}

.ant-select-dropdown {
  background-color: @light-grey-color;
  border-radius: 8px;
  border: 1px solid #cecece;
  max-height: 500px;
  overflow-y: auto;
}

.ant-select:not(.ant-select-customize-input) .ant-select-selector {
   background-color: @light-grey-color;
  border-radius: 8px;
  // border: 1px solid #cecece;
  max-height: 500px;
  overflow-y: auto;
  min-height: 40px;
}

.ant-select-dropdown::-webkit-scrollbar {
  width: 5px;
}

.ant-select-dropdown::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.ant-select-dropdown::-webkit-scrollbar-thumb {
  background-color: #a2c3f8;
  border-radius: 10px;
}

.ant-select-dropdown::-webkit-scrollbar-thumb:hover {
  background-color: #555;
}

.ant-select-arrow {
  color: @blue-color;
  font-size: 10px;
  transition: transform 0.3s ease-in-out;
}

.ant-select-open .ant-select-arrow {
  transform: rotate(180deg);
}

.ant-select-show-search .ant-select-arrow {
  transform: none;
}

.ant-select-item {
  background-color: @light-grey-color;
  color: #333333;

  &:hover {
    background-color: #eaeaed;
  }
}

.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  background-color: #eaeaed;
}

.ant-select-status-error.ant-select:not(.ant-select-disabled):not(.ant-select-customize-input):not(
    .ant-pagination-size-changer
  )
.ant-select-selector {
  background: @light-grey-color;
  border: 1px solid #fe1d00;
}

.ant-select-show-search.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  border: none;
}

// ant-input----------------
.ant-input-stepperless {
  background: @light-grey-color;
  border: none;
  height: @input-height;
  border-radius: 8px;
}

.ant-input:focus,
.ant-input-focused {
  border: none;
  box-shadow: none;
}

.ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input,
.ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input:hover {
  background: @light-grey-color;
  border: 1px solid #fe1d00;
}

.ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input:focus,
.ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input-focused {
  box-shadow: none;
}

//ant-input-number------------
.ant-input-number {
  border: none;
}

.ant-input-number-focused {
  box-shadow: none;
}

.ant-input-number-input {
  background: @light-grey-color;
  border: none;
  height: @input-height;
  border-radius: 8px;
}

.ant-input-number-handler-wrap {
  background-color: @light-grey-color;
  border-radius: 8px;
  display: none;
}

.ant-input-number:focus,
.ant-input-number-focused {
  box-shadow: none;
  border: none;
}

.ant-input-number-status-error {
  border-radius: 8px;
  border: 1px solid #fe1d00;
}

.ant-input-number-status-error:not(.ant-input-number-disabled):not(
    .ant-input-number-borderless
  ).ant-input-number:focus,
.ant-input-number-status-error:not(.ant-input-number-disabled):not(
    .ant-input-number-borderless
  ).ant-input-number-focused {
  box-shadow: none;
}

//ant-picker---------
.ant-picker {
  height: @input-height;
  background-color: @light-grey-color;
  border: none;
  border-radius: 8px;
}

.ant-picker-focused {
  box-shadow: none;
}

.ant-picker-panel {
  background-color: @light-grey-color !important;
  border-radius: 8px !important;
}

.ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before {
  border: 1px solid @blue-color !important;
}

.ant-picker-cell-selected .ant-picker-cell-inner {
  background-color: @blue-color !important;
}

.ant-picker-footer .ant-picker-today-btn {
  color: @blue-color !important;
}

.ant-picker-status-error.ant-picker {
  border: 1px solid #fe1d00;
}

.ant-picker-status-error.ant-picker,
.ant-picker-status-error.ant-picker:not(.ant-picker-disabled):hover {
  background-color: @light-grey-color;
}

.ant-picker-status-error.ant-picker-focused,
.ant-picker-status-error.ant-picker:focus {
  box-shadow: none;
}

.ant-modal-content {
  border-radius: 8px;
  padding: 10px 40px;

  .ant-modal-body {
    padding: 24px 0;
  }
}

.no-paddings .ant-modal-content {
  padding: 0;

  .ant-modal-body {
    padding: 0;
  }
}

.ant-modal-header {
  text-align: center;
}

@media (max-width: @mobile-width) {
  .ant-modal-content {
    padding: 10px 20px;
  }

  .ant-modal {
    width: 90% !important;
  }
}


// ant-checkbox --------------------
.ant-checkbox-inner {
  border: 2px solid #CACACA;
  border-radius: 3px;
}

.ant-checkbox-wrapper:hover .ant-checkbox-inner, .ant-checkbox:hover .ant-checkbox-inner, .ant-checkbox-input:focus + .ant-checkbox-inner {
  color: #2C7DF6;
}

.ant-checkbox-checked .ant-checkbox-inner {
  background-color: #2C7DF6;
  border-color: #2C7DF6;
}

.ant-checkbox-checked::after {
  border: 1px solid #2C7DF6;
}

.ant-checkbox-inner::after {
  left: 20%;
}
